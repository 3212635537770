<template>
  <!-- 支线业务线配置 -->
  <div class="container">
    <a-page-header title="支线业务线配置" sub-title="活动" />

    <div class="main-content">
      <div class="main-content-header">
        <div class="item">
          <div class="title">
            <span class="title-1">业务支线</span>
          </div>
        </div>
      </div>
      <div class="table-wrap">
        <a-table
          :columns="columns"
          :data-source="dataSource"
          :pagination="false"
          :row-key="(record) => record.id"
          :loading="loading"
          :scroll="{ x: 660 }"
        >
          <template slot="type">
            <span>业务支线</span>
          </template>
          <template slot="action" slot-scope="record">
            <a-button
              type="link"
              @click="
                $jump({
                  path: '/activityConfigInfo',
                  query: {
                    id: record.id,
                    name: record.name
                  }
                })
              "
            >
              更多详情
            </a-button>
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      dataSource: [],
      columns: [
        {
          title: '序号',
          width: 60,
          customRender: (text, record, index) => index + 1
        },
        {
          title: '业务线名称',
          dataIndex: 'name',
          width: 200
        },
        {
          title: '业务线类型',
          scopedSlots: { customRender: 'type' }
        },
        {
          title: '操作',
          fixed: 'right',
          width: 200,
          scopedSlots: { customRender: 'action' }
        }
      ],
      loading: true
    }
  },
  computed: {
    ...mapState(['pageTitle'])
  },
  created() {
    this.getActivityConfig()
  },
  methods: {
    getActivityConfig() {
      // 获取支线业务线配置（活动）
      const data = {
        page: 1,
        limit: 10
      }
      this.$axios.getActivityConfig(data).then((res) => {
        this.dataSource = res.data.data.data
        this.loading = false
      })
    }
  }
}
</script>

<style scoped></style>
